import React, { useState } from 'react';
import './InfoCard.css';

function InfoCard({ title, content }) {
    const [isExpanded, setIsExpanded] = useState(false);

    const toggleExpansion = () => {
        setIsExpanded(!isExpanded);
    };

    const renderContent = () => {
        if (Array.isArray(content)) {
            return content.map((paragraph, index) => (
                <p key={index} dangerouslySetInnerHTML={{ __html: paragraph }} />
            ));
        }
        return <p dangerouslySetInnerHTML={{ __html: content }} />;
    };

    return (
        <div className={`info-card ${isExpanded ? 'expanded' : ''}`}>
            <h3>{title}</h3>
            <div className="card-content">
                {renderContent()}
            </div>
            <button onClick={toggleExpansion} className="read-more-button">
                {isExpanded ? 'Read Less' : 'Read More'}
            </button>
        </div>
    );
}

export default InfoCard;
