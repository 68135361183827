import React from 'react';
import './LanguageDropdown.css';

function LanguageDropdown({ onSelect, disabledLanguage }) {
    const languages = [
        "English",
        "Indonesian",
        "Spanish",
        "French",
        "German",
        "Italian"
    ];

    return (
        <select 
            className="language-dropdown"
            onChange={(e) => onSelect(e.target.value)}
            defaultValue=""
        >
            <option value="" disabled>Select a language</option>
            {languages.map((language) => (
                <option 
                    key={language} 
                    value={language}
                    disabled={language === disabledLanguage}
                >
                    {language}
                </option>
            ))}
        </select>
    );
}

export default LanguageDropdown;
