import React, { useState } from "react";
import { Routes, Route } from "react-router-dom"; // Only Routes and Route here
import Header from "./Header";
import LandingPage from "./LandingPage";
import DownloadPage from "./DownloadPage";
import HelpPage from "./HelpPage";
import GetApp from './GetApp';
import Privacy from "./Privacy";
import TermsOfService from "./TermsOfService";
import FeedbackPage from "./FeedbackPage";
import "./App.css";


function App() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <div>
      <Header isMenuOpen={isMenuOpen} setIsMenuOpen={setIsMenuOpen} />
      <Routes>
        <Route path="/" element={<LandingPage closeMenu={closeMenu} />} />
        <Route path="/download" element={<DownloadPage />} />
        <Route path="/help" element={<HelpPage />} />
        <Route path="/get-click-n-learn" element={<GetApp />} />
        <Route path="/privacy-policy" element={<Privacy />} />
        <Route path="/terms-of-service" element={<TermsOfService />} />
        <Route path="/feedback" element={<FeedbackPage />} />
      </Routes>
    </div>
  );
}
export default App;
