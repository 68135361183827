import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import './FeedbackPage.css';

const FeedbackPage = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        feedback: '',
        about: '',
        nativeLanguage: '',
        learningLanguage: '',
        commentType: '',
        recommend: '',
        experience: '',
        device: ''
    });
    const [submitted, setSubmitted] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        emailjs
            .send(
                'service_hhqtx2m', // Replace with your Service ID
                'template_k3ngonf', // Replace with your Template ID
                formData,           // The form data to send
                'aKhEL7jx_LGN9Fo9y'      // Replace with your User ID
            )
            .then(
                (result) => {
                    console.log('Feedback sent:', result.text);
                    setSubmitted(true);
                },
                (error) => {
                    console.error('Error:', error.text);
                }
            );
    };

    return (
        <div className="feedback-container">
            <h1>Feedback</h1>
            {submitted ? (
                <div className="thank-you-message">
                    <p>Thank you for your feedback!</p>
                </div>
            ) : (
                <form className="feedback-form" onSubmit={handleSubmit}>
                    <label htmlFor="name">Name:</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        placeholder="e.g., John, Mary..."
                    />

                    <label htmlFor="email">Email address:</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="e.g., name@example.com"
                    />

                    <label htmlFor="feedback">Your Feedback:</label>
                    <textarea
                        id="feedback"
                        name="feedback"
                        value={formData.feedback}
                        onChange={handleChange}
                        placeholder="Let us know your thoughts..."
                        maxLength="500"
                    ></textarea>

                    <h2>Additional Helpful Information</h2>

                    <label htmlFor="about">This is about:</label>
                    <select
                        id="about"
                        name="about"
                        value={formData.about}
                        onChange={handleChange}
                    >
                        <option value="" disabled>Select</option>
                        <option value="Android App">Android App</option>
                        <option value="Website">Website</option>
                    </select>

                    <label htmlFor="commentType">My comment is about:</label>
                    <select
                        id="commentType"
                        name="commentType"
                        value={formData.commentType}
                        onChange={handleChange}
                    >
                        <option value="" disabled>Select</option>
                        <option value="A suggestion">A suggestion</option>
                        <option value="A bug report">A bug report</option>
                        <option value="A general question">A general question</option>
                    </select>

                    <label htmlFor="device">What device are you using?</label>
                    <input
                        type="text"
                        id="device"
                        name="device"
                        value={formData.device}
                        onChange={handleChange}
                        placeholder="e.g., Samsung Galaxy S21"
                    />

                    <label htmlFor="nativeLanguage">My native language is:</label>
                    <input
                        type="text"
                        id="nativeLanguage"
                        name="nativeLanguage"
                        value={formData.nativeLanguage}
                        onChange={handleChange}
                        placeholder="e.g., English"
                    />

                    <label htmlFor="learningLanguage">I want to learn:</label>
                    <input
                        type="text"
                        id="learningLanguage"
                        name="learningLanguage"
                        value={formData.learningLanguage}
                        onChange={handleChange}
                        placeholder="e.g., Indonesian"
                    />


                    <label htmlFor="recommend">How likely are you to recommend this app to others? (1-5):</label>
                    <select
                        id="recommend"
                        name="recommend"
                        value={formData.recommend}
                        onChange={handleChange}
                    >
                        <option value="" disabled>Select</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                        <option value="4">4</option>
                        <option value="5">5</option>
                    </select>

                    <label htmlFor="experience">Rate your experience:</label>
                    <select
                        id="experience"
                        name="experience"
                        value={formData.experience}
                        onChange={handleChange}
                    >
                        <option value="" disabled>Select</option>
                        <option value="Excellent">Excellent</option>
                        <option value="Good">Good</option>
                        <option value="Fair">Fair</option>
                        <option value="Poor">Poor</option>
                    </select>

                    <button type="submit">Submit</button>
                </form>
            )}
        </div>
    );
};

export default FeedbackPage;
