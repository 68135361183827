import React from "react";
import "./GetApp.css";

function GetApp() {
    return (
        <div className="get-app-container">
            <div className="download-section">
                <h1>Get Click-n-Learn Mobile</h1>
                <div className="download-options">
                    <div className="download-card">
                        <i className="fab fa-android"></i>
                        <h2>Android</h2>
                        <a 
                            href="https://play.google.com/store/search?q=Google%20translage&c=apps&hl=en" 
                            className="download-button"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Get it on Google Play
                        </a>
                    </div>
                    <div className="download-card coming-soon">
                        <i className="fab fa-apple"></i>
                        <h2>iOS</h2>
                        <div className="coming-soon-text">Coming Soon</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GetApp;