import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import InfoCard from "./InfoCard";
import "./LandingPage.css";
import Footer from "./Footer";

function LandingPage({ closeMenu }) {
    const [currentWord, setCurrentWord] = useState("Easy");

    useEffect(() => {
        const words = ["Easy", "Fun", "Quick"];
        const intervalId = setInterval(() => {
            setCurrentWord((prevWord) => {
                const currentIndex = words.indexOf(prevWord);
                const nextIndex = (currentIndex + 1) % words.length;
                return words[nextIndex];
            });
        }, 2000);

        return () => clearInterval(intervalId);
    }, []);

    const cardData = [
        {
            title: "Mastery Through vocabulary",
            content: [
                `Mastering a basic vocabulary is fundamental to learning a new language. Language learning programs can do an excellent job of teaching you the syntax of a language, but often dump numerous new words on you in each lesson. This can feel overwhelming and discouraging.`,
                `There isn't a one-size-fits-all rule, but linguistic research provides some general guidelines on vocabulary size for effective communication in a new language.`,
                `Studies suggest that learning the most frequently used 2,000 to 3,000 word families can enable you to handle everyday conversations and understand a significant portion of written texts. For example, in English, the 2,000 most common words account for about 80-90% of the words used in daily communication.`,
                `The goal of Click-n-Learn is to make learning a basic vocabulary easy, fast and enjoyable. There are two different activities: <b>Pick-n-Learn</b> where you select the correct answer from a list of possible answers, and <b>Type-n-Learn</b> where you hone your learning by typing the correct answer, made easier with available hints. Essential functions are controlled through an easy to use settings screen enabling you to adapt Click-n-Learn to your unique learning style.`,
            ],
        },
        {
            title: 'Two Interactive Modes',
            content: [
                `At Click-n-Learn, we understand that different learning methods can significantly impact how effectively you absorb new vocabulary. That's why we've developed two distinct activities to cater to your learning needs: Pick-n-Learn and Type-n-Learn.`,
                `<b>Pick-n-Learn: Strengthen Recognition through Multiple Choice</b>`,
                `In the Pick-n-Learn activity, you enhance your vocabulary by selecting the correct answer from a list of possible options. This method leverages recognition memory, which is the ability to identify previously encountered words or concepts. Recognition tasks are particularly effective for beginners as they provide contextual cues that make learning less daunting. By repeatedly recognizing the correct words in various contexts, you build a strong foundation that helps in quickly expanding your vocabulary. This approach reduces cognitive load, making it easier to remember new words without feeling overwhelmed.`,
                `<b>Type-n-Learn: Boost Recall with Active Engagement</b>`,
                `The Type-n-Learn activity takes your learning a step further by encouraging you to type the correct answer, with hints available to guide you if needed. This method engages recall memory, which requires you to retrieve information without explicit prompts. Actively recalling and typing out words reinforces neural pathways associated with language production. This not only improves your spelling and writing skills but also enhances your ability to use the new vocabulary spontaneously in conversations. Research shows that active recall practices significantly improve memory retention compared to passive study methods.`,
                `<b>Why Both Methods Enhance Your Learning Experience</b>`,
                `By incorporating both recognition and recall exercises, Click-n-Learn offers a comprehensive learning experience that aligns with established cognitive learning theories. Recognition activities like Pick-n-Learn are excellent for initial exposure and familiarization. They help you quickly identify and understand new words in context. Recall activities like Type-n-Learn solidify that knowledge by requiring you to produce the language actively, which is crucial for fluency.`,
                `This dual-method approach ensures that you're not just memorizing words but truly integrating them into your active vocabulary. It caters to different learning styles and allows you to progress at your own pace, making the journey of learning a new language more personalized and effective.`
            ],
        },
        {
            title: "Not Just For Languages!",
            content: [
                `Learn any subject that could otherwise be learned in a flash card format. Create your own sets with a simple text format.`,
                
                `<b>Countries and Their Capitals:</b>\n` +
                `Canada, Ottawa\n` +
                `Brazil, Brasília\n\n`,
                
                `<b>Chemical Elements and Their Symbols:</b>\n` +
                `Oxygen, O\n` +
                `Gold, Au\n\n`,
                
                `<b>Historical Events and Dates:</b>\n` +
                `Moon Landing, 1969\n` +
                `Fall of the Berlin Wall, 1989\n\n`,

                `<b>Organic Compounds and Their Functional Groups:</b>\n` +
                `Ethanol, Alcohol (-OH) group\n` +
                `Acetone, Ketone (>C=O) group\n\n` +

                `<b>Common Names and IUPAC Names of Organic Compounds:</b>\n` +
                `Acetic Acid, Ethanoic Acid\n` +
                `Formaldehyde, Methanal\n\n`

            ],
        },
    ];

    return (
        <div className="landing-container">
            {/* Hero Section */}
            <div className="hero">
                <h1>Make Learning</h1>
                <h1 key={currentWord} className="animated-word">
                    {currentWord}
                </h1>
                <h1>With Click-n-Learn</h1>
            </div>
            {/* Activities Section */}
            <div className="activities">
                <h1>Two Ways to Learn...</h1>
                <div className="activity-row">
                    <div className="activity-text">
                        <h3>Pick-N-Learn</h3>
                        <p>
                            Just touch the correct answer for each question, or
                            alternate Questions and Answers for real power
                            learning.
                        </p>
                    </div>
                    <div className="activity-image">
                        <img
                            src={`${process.env.PUBLIC_URL}/pick-n-learn.png`}
                            alt="Pick-n-Learn"
                        />
                    </div>
                </div>
                <div className="activity-row">
                    <div className="activity-text">
                        <h3>Type-N-Learn</h3>
                        <p>
                            Fill in the blanks style with hints and automatic
                            answer checking.
                        </p>
                    </div>
                    <div className="activity-image">
                        <img
                            src={`${process.env.PUBLIC_URL}/type-n-learn.png`}
                            alt="Type-n-Learn"
                        />
                    </div>
                </div>
                <div className="activity-row">
                    <div className="activity-text">
                        <h3>Download Files</h3>
                        <p>
                            Choose from hundreds of free language learning
                            files. Or create your own files with a simple text
                            format:
                        </p>
                        <code className="custom-code">
                            question, answer <br />
                            Terima Kasih, Thank you <br />
                            Sama-sama, You're welcome
                        </code>
                    </div>
                    <div className="activity-image">
                        <img
                            src={`${process.env.PUBLIC_URL}/file-download.png`}
                            alt="Download Files"
                        />
                    </div>
                </div>
                <div className="activity-row">
                    <div className="activity-text">
                        <h3>Customize Settings</h3>
                        <p>
                            Customize and Personalize your learning experience
                            with easy-to-use settings.
                        </p>
                    </div>
                    <div className="activity-image">
                        <img
                            src={`${process.env.PUBLIC_URL}/settings-screen.jpg`}
                            alt="Settings Screen"
                        />
                    </div>
                </div>
            </div>

            {/* New Cards Section */}
            <div className="additional-info">
                <h1>Learn More</h1>
                <div className="cards-container">
                    {cardData.map((card, index) => (
                        <InfoCard
                            key={index}
                            title={card.title}
                            content={card.content}
                        />
                    ))}
                </div>
            </div>

            <Link
                to="/get-click-n-learn"
                className="floating-button"
                onClick={closeMenu}
            >
                Get Click-n-Learn
            </Link>
            <Footer />
        </div>
    );
}

export default LandingPage;
