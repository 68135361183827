import React, { useState, useEffect, useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LanguageDropdown from "./LanguageDropdown";
import "./DownloadPage.css";
import Footer from "./Footer";
import Slider from "react-slick";

// Custom Arrow Component for "Next"
function NextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} custom-arrow`}
            style={{ ...style, display: "block", right: "-40px" }}
            onClick={onClick}
        >
            <span className="arrow-symbol">&gt;</span>
        </div>
    );
}

// Custom Arrow Component for "Prev"
function PrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div
            className={`${className} custom-arrow`}
            style={{ ...style, display: "block", left: "-40px" }}
            onClick={onClick}
        >
            <span className="arrow-symbol">&lt;</span>
        </div>
    );
}

function DownloadPage() {
    const [nativeLanguage, setNativeLanguage] = useState(null);
    const [learningLanguage, setLearningLanguage] = useState(null);
    const [availableSets, setAvailableSets] = useState([]);
    const [isSameLanguage, setIsSameLanguage] = useState(false);
    const [showDownloadMessage, setShowDownloadMessage] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const sliderRef = useRef(null);

    const urlParams = new URLSearchParams(window.location.search);
    const isInApp =
        urlParams.get("source") === "ClickNLearnApp" ||
        navigator.userAgent.includes("ClickNLearnApp") ||
        window.isClickNLearnApp;

    const BACKEND_URL = "https://api.click-n-learn.com";

    const handleNativeLanguageSelect = (language) =>
        setNativeLanguage(language);
    const handleLearningLanguageSelect = (language) =>
        setLearningLanguage(language);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
  
    useEffect(() => {
        if (nativeLanguage && learningLanguage) {
            if (nativeLanguage === learningLanguage) {
                setIsSameLanguage(true);
                setAvailableSets([]);
            } else {
                setIsSameLanguage(false);
                setIsLoading(true);
                const fetchAvailableSets = async () => {
                    try {
                        const response = await fetch(
                            `${BACKEND_URL}/available-sets?nativeLanguage=${nativeLanguage}&learningLanguage=${learningLanguage}`
                        );
                        const data = await response.json();
                        setAvailableSets(data);
                    } catch (error) {
                        console.error("Error fetching available sets:", error);
                    } finally {
                        setIsLoading(false);
                    }
                };
                fetchAvailableSets();
            }
        } else {
            setIsSameLanguage(false);
            setAvailableSets([]);
        }
    }, [nativeLanguage, learningLanguage]);

    useEffect(() => {
        if (sliderRef.current) {
            sliderRef.current.slickGoTo(0);
        }
    }, [availableSets]);

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        swipe: true, // Allow swiping
        swipeToSlide: true, // Enable swiping to any slide
        draggable: true, // Allow dragging with the mouse
        touchMove: true, // Enable touch gestures for mobile devices
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    const handleDownload = async (set) => {
        if (isInApp) {
            try {
                const response = await fetch(
                    `${BACKEND_URL}/words?nativeLanguage=${nativeLanguage}&learningLanguage=${learningLanguage}&setNumber=${set.set_number}`
                );
                const data = await response.json();
                const words = data.words;

                console.log(
                    "Downloading set:",
                    set.set_number,
                    "Words:",
                    words
                );

                if (
                    window.ReactNativeWebView &&
                    window.ReactNativeWebView.postMessage
                ) {
                    window.ReactNativeWebView.postMessage(
                        JSON.stringify({
                            type: "DOWNLOAD_WORDS",
                            payload: {
                                nativeLanguage: nativeLanguage,
                                learningLanguage: learningLanguage,
                                setNumber: set.set_number,
                                words: words,
                            },
                        })
                    );
                    console.log("Message sent to app");
                } else {
                    console.log("ReactNativeWebView not available");
                }
            } catch (error) {
                console.error("Error fetching words for set:", error);
            }
        } else {
            setShowDownloadMessage(true);
        }
    };

    return (
        <div className="download-page-wrapper">
            <div className="download-page-content">
                <div className="language-selection-container">
                    <h3 className="language-selection">
                        Select your native language:
                    </h3>
                    <LanguageDropdown onSelect={handleNativeLanguageSelect} />
                </div>
                <div className="language-selection-container">
                    <h3 className="language-selection">
                        Select the language you'd like to learn:
                    </h3>
                    <LanguageDropdown
                        onSelect={handleLearningLanguageSelect}
                        disabledLanguage={nativeLanguage}
                    />
                </div>
                {showDownloadMessage ? (
                    <div className="download-message">
                        <p>
                            To download, get Click-n-Learn from the Google Play
                            Store.
                        </p>
                        <button onClick={() => setShowDownloadMessage(false)}>
                            Back
                        </button>
                    </div>
                ) : (
                    <div className="carousel-container availSets-container">
                        {isSameLanguage ? (
                            <p className="language-error-message">
                                The native language and the learning language cannot
                                be the same.
                            </p>
                        ) : isLoading ? (
                            <div className="loading-container">
                                <div className="loading-spinner"></div>
                                <p>Loading Language Sets</p>
                            </div>
                        ) : (
                            <>
                                <h3>Available Sets:</h3>
                                <Slider
                                    ref={sliderRef}
                                    {...settings}
                                    key={availableSets.length}
                                >
                                    {availableSets.map((set) => (
                                        <div
                                            key={set.set_number}
                                            className="availSets-card"
                                        >
                                            <p>
                                                {nativeLanguage} -{" "}
                                                {learningLanguage}
                                            </p>
                                            <p>
                                                Set #{set.set_number} -{" "}
                                                {set.word_count} Words
                                            </p>
                                            <p>{set.description}</p>
                                            <button
                                                onClick={() => handleDownload(set)}
                                                className="asDownload-button"
                                            >
                                                Download
                                            </button>
                                        </div>
                                    ))}
                                </Slider>
                            </>
                        )}
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
}
export default DownloadPage;
